import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Canvas,
} from "@react-pdf/renderer";

import ExportPDFTable from "./ExportPDFTable";
import { prettyEuro, arrondi } from "../../utils";
import {
  calculChiffreAffaireHFA,
  calculCoutTotalFraisDepenses,
  calculFinancementParticipatif,
  calculFraisAcquisition,
  calculFraisAgenceVente,
  calculFraisFinancenementAvecMontantExpertise,
  calculMontantTotalDepenses,
  calculMontantTravaux,
  calculAFinancerPerso,
  calculMargeNette,
  calculTauxMargeNette,
  calculMontantAFinancer,
  calculApport,
  calculTVATotalLot,
} from "./helpers/calculsProjet";
import TypeProjet from "../../pages/Components/data/TypeProjet.json";
import GrandTotalPDFTable from "./GrandTotalPDFTable";
import SommePDFTable from "./SommePDFTable copy";
import MargeNettePDFTable from "./MargeNettePDFTable";

const DEBUG = false;
const baseURL = process.env.REACT_APP_BASEURL;

// Create styles
const styles = StyleSheet.create({
  mainPage: {
    paddingTop: 35,
    paddingBottom: 65,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  canvas: {
    marginTop: 50,
    marginLeft: 250,
    backgroundColor: "#3BB599",
    height: 10,
    width: 100,
  },
  image: {
    marginTop: 50,
    width: "100%",
  },
  maintitle: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 10,
  },
  typeProjet: {
    marginTop: 70,
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 50,
    color: "#3BB599",
  },
  title: {
    fontSize: 24,
    textAlign: "left",
    fontWeight: "bold",
    color: "#3BB599",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 10,
  },
  situation: {
    fontSize: 12,
    textAlign: "center",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  header: {
    fontSize: 12,
    letterSpacing: 0.4,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  portePar: {
    fontSize: 12,
    letterSpacing: 0.4,
    marginTop: 30,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

// Create Document Component
const ExportPDF = ({ projet }) => {
  // console.log(projet);
  useEffect(() => {
    //console.log(projet);
    async function getUseEffectData() {
      try {
      } catch (error) {
        console.log(error);
      }
    }

    getUseEffectData();
  }, [projet]);
  return (
    <Document debug={DEBUG}>
      <Page style={styles.mainPage} debug={DEBUG}>
        <Text style={styles.header} fixed debug={DEBUG}>
          PRÉSENTATION PROJET MARCHAND DE BIENS
        </Text>
        <Canvas style={styles.canvas} debug={DEBUG} />
        <Image
          debug={DEBUG}
          style={styles.image}
          src={`https://api.flipping.fr/static/export-pdf-01.png`}
        />

        <Text style={styles.typeProjet}>
          {TypeProjet[projet.typeProjet].toUpperCase()}
        </Text>
        <Text style={styles.situation}>{projet.adresse.toUpperCase()}</Text>
        <Text style={styles.situation}>
          {projet.codePostal} {projet.ville.toUpperCase()}
        </Text>
        <Image
          debug={DEBUG}
          style={styles.image}
          src={`https://api.flipping.fr/static/export-pdf-02.png`}
        />
        <Text style={styles.portePar} fixed>
          PROJET PORTÉ PAR {projet.Utilisateur.prenom.toUpperCase()}{" "}
          {projet.Utilisateur.nom.toUpperCase()}
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page style={styles.body}>
        <Text style={styles.title}>BILAN FINANCIER</Text>
        <Text style={styles.subtitle}>PREVISIONNEL FINANCIER</Text>
        <ExportPDFTable
          data={{
            showHeader: false,
            column: [
              { label: "category", align: "left" },
              { label: "amount", align: "right" },
            ],
            data: [
              {
                category: "Prix d'acquisition",
                amount: prettyEuro(projet.prixAchat),
              },
              {
                category: "Frais d'agence",
                amount: prettyEuro(projet.fraisAgence),
              },
              {
                category: "Frais de notaire",
                amount: prettyEuro(projet.fraisNotaire),
              },
            ],
          }}
        />
        <SommePDFTable
          label="Total acquisition"
          amount={prettyEuro(calculFraisAcquisition(projet))}
        />
        <div
          style={{
            marginBottom: 20,
          }}
        />
        <ExportPDFTable
          data={{
            showHeader: false,
            column: [
              { label: "category", align: "left" },
              { label: "amount", align: "right" },
            ],
            data: [
              {
                category: "Travaux",
                amount: prettyEuro(
                  calculMontantTravaux(projet.ProjetTravauxes)
                ),
              },
              {
                category: "Frais de financement",
                amount: prettyEuro(
                  calculFraisFinancenementAvecMontantExpertise(projet)
                ),
              },
              {
                category: "Financement participatif",
                amount: prettyEuro(calculFinancementParticipatif(projet)),
              },
            ],
          }}
        />
        <SommePDFTable
          label="Coût total frais/dépenses"
          amount={prettyEuro(calculCoutTotalFraisDepenses(projet))}
        />
        <div
          style={{
            marginBottom: 20,
          }}
        />
        <GrandTotalPDFTable
          label="Coût total du projet"
          amount={prettyEuro(calculMontantTotalDepenses(projet))}
        />
        <div
          style={{
            marginBottom: 20,
          }}
        />
        <GrandTotalPDFTable
          label="Chiffre d'affaires HFA"
          amount={prettyEuro(calculChiffreAffaireHFA(projet))}
        />
        <div
          style={{
            marginBottom: 20,
          }}
        />
        <GrandTotalPDFTable
          label="Frais d'agence"
          amount={prettyEuro(calculFraisAgenceVente(projet))}
        />
        <div
          style={{
            marginBottom: 20,
          }}
        />
        <GrandTotalPDFTable
          label="Chiffre d'affaires FAI"
          amount={prettyEuro(
            calculChiffreAffaireHFA(projet) + calculFraisAgenceVente(projet)
          )}
        />
        <div
          style={{
            marginBottom: 20,
          }}
        />
        <MargeNettePDFTable
          label="Marge nette"
          amount={`(${arrondi(calculTauxMargeNette(projet))}%) ${prettyEuro(
            calculMargeNette(projet)
          )}`}
        />
        <div
          style={{
            marginBottom: 20,
          }}
        />
        <ExportPDFTable
          data={{
            showHeader: true,
            column: [
              { label: "category", align: "left", header: "Chiffres clés" },
              { label: "amount", align: "right", header: "Montant" },
            ],
            data: [
              {
                category: "Coût total du projet",
                amount: {
                  value: prettyEuro(calculMontantTotalDepenses(projet)),
                  color: "blue",
                },
              },
              {
                category: "Apport",
                amount: {
                  value: prettyEuro(calculApport(projet)),
                  color: "red",
                },
              },
              {
                category: "Montant à financer",
                amount: {
                  value: prettyEuro(calculMontantAFinancer(projet)),
                  color: "orange",
                },
              },
              {
                category: "Financement personnel",
                amount: {
                  value: prettyEuro(calculAFinancerPerso(projet)),
                  color: "red",
                },
              },
            ],
          }}
        />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page style={styles.body}>
        <Text style={styles.title}>ANNEXES</Text>
        <Text style={styles.subtitle}>Liste des ventes</Text>
        <ExportPDFTable
          data={{
            showHeader: true,
            column: [
              { label: "lot", align: "left", header: "N° de lot" },
              {
                label: "hfa",
                align: "left",
                header: "Prix de vente",
              },
              {
                label: "fa",
                align: "left",
                header: "Frais d'agence",
              },
              {
                label: "tva",
                align: "left",
                header: "Montant TVA",
              },
              {
                label: "total",
                align: "left",
                header: "Total",
              },
            ],
            data: projet.ProjetLots.map((lot) => {
              return {
                lot: `Vente n°${lot.itemOrder}`,
                hfa: prettyEuro(lot.prixVente),
                fa: prettyEuro(lot.fraisAgence),
                tva: prettyEuro(calculTVATotalLot(projet, lot.uniqueId)),
                total: prettyEuro(
                  lot.prixVente +
                    lot.fraisAgence +
                    calculTVATotalLot(projet, lot.uniqueId)
                ),
              };
            }),
          }}
        />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default ExportPDF;
